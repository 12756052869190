<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";


/**
 * Available Wholesalers Component
 */
export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  components: {

    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        items: [],
      },

      industries: null,
      deliveryUnitsMap: null
    };
  },

  methods: {
    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('available-wholesalers.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "name", label: 'Hurtownia'},
        {
          key: "industryId", label: this.$t('message.industry'), formatter: (value) => {
            const industry = this.getIndustry(value)
            if (!industry) {
              return "-"
            }

            return industry.name
          }
        },
        {key: "deliveryTime", slot: true, label: this.$t('message.deliveryTime')}
      ]
    },

    getIndustry(id) { // TODO: make helper
      if (!this.industries) {
        return {}
      }

      return this.industries.get(id) || null;
    },

    async loadIndustries() {
      try {
        if (this.table.items.length === 0) {
          return;
        }

        const ids = [];
        for (let warehouse of this.table.items) {
          if (warehouse.industryId) {
            ids.push(warehouse.industryId);
          }
        }

        const json = {
          ids: ids
        }

        const result = await axios.post(`/industry/fetch`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.industries = new Map(result.data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadWarehouses() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const result = await axios.get(`/warehouse/list/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": page,
            "size": this.table.perPage
          }
        });

        this.table.items = result.data
        await this.loadWarehousesCount()
        await this.loadIndustries()

        return this.table.items;
      } catch (error) {
        errorCatcher.catchErrors(error)
        return []
      }
    },

    async loadWarehousesCount() {
      try {
        const result = await axios.get(`/warehouse/count`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.table.totalRows = result.data;
        this.table.rows = result.data;
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadDeliveryUnits() {
      try {
        const {data} = await axios.get(`/delivery-unit`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.deliveryUnitsMap = new Map(data.deliveryUnits.map((obj) => [obj.deliveryUnit, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getDeliveryUnit(unit) {
      if (!this.deliveryUnitsMap) {
        return null
      }

      return this.deliveryUnitsMap.get(unit) || null
    },

    getWarehouseDeliveryTime(warehouse) {
      if (warehouse) {
        const deliveryUnit = this.getDeliveryUnit(warehouse.deliveryUnit)
        if (deliveryUnit) {
          return warehouse.deliveryTime + " " + deliveryUnit.name
        }
      }

      return this.$t('products.no-delivery-time')
    }

  },

  async created() {
    await this.loadDeliveryUnits()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('available-wholesalers.title')"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <ecat-table
                :table="table"
                :fields="getFields()"
                :items="loadWarehouses"
                :pagination-top="true"

            >
              <template v-slot:deliveryTime="{ item }">
                <span>{{ getWarehouseDeliveryTime(item) }}</span>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>